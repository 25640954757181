
import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import UserProfile from "./pages/UsersProfile/UserProfile";

function App() {
  const theme = useSelector((state) => state.theme.theme);
  useEffect(() => {
    const importThemeStyle = async () => {
      const styleSheetId = "themeStyleSheet";
      const existingStyleSheet = document.getElementById(styleSheetId);
      if (existingStyleSheet) {
        existingStyleSheet.remove();
      }
      const link = document.createElement("link");
      link.id = styleSheetId;
      link.rel = "stylesheet";
      link.href = theme ? "./style.css" : "./style2.css";
      document.head.appendChild(link);
    };

    importThemeStyle();
  }, [theme]);

  return (
    <>
      <Routes>
        <Route path="/profile/*" element={<UserProfile />}/>
      </Routes>
    </>
  );
}

export default App;
